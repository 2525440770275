@import '@hiredigital/ui/variables';

.list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% + 40px);
  margin: -20px;

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
}

.item {
  display: flex;
  padding: 40px;
  width: calc(50% - 40px);
  border-radius: 5px;
  background-color: $white;
  margin: 20px;
  box-sizing: border-box;
  box-shadow: $shadow-30;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include for-size(tablet-landscape-down) {
    width: initial;
    padding: 20px;
  }

  @include for-size(tablet-portrait-down) {
    flex-direction: column;
    width: initial;
    padding: 20px;
  }
}

.imageContainer {
  flex-shrink: 0;
}
.image {
  width: 100px;
  height: 100px;
  @include for-size(tablet-portrait-down) {
    margin-bottom: 25px;
    width: 100px;
    height: 100px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex: 1;

  &.contentReversed {
    margin-left: 0;
    margin-right: 30px;

    @include for-size(tablet-portrait-down) {
      margin: 0;
    }
  }

  @include for-size(tablet-portrait-down) {
    width: 100%;
    margin: 0;
  }
}

.headline {
  font-size: $size-5;
  line-height: $line-height-5;
  font-weight: $fw-2;
  margin-bottom: 8px;
}

.description {
  font-size: $size-3;
  line-height: $line-height-3;
  margin-bottom: 16px;
}

.action {
  margin-top: auto;
}
