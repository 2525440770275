@import '@hiredigital/ui/variables';

.container {
  display: flex;
  align-items: center;
  height: 325px;
  @include for-size(tablet-landscape-down) {
    height: 400px;
  }

  @include for-size(tablet-landscape-down, -100) {
    height: auto;
  }
  @include for-size(phone-only) {
    height: auto;
  }
}

.pictureContainer {
  position: relative;
  height: 100%;
  width: 250px;
  flex-shrink: 0;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  @include for-size(phone-only) {
    display: none;
  }
}

.picture {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  object-fit: cover;
}

.itemContainer {
  box-sizing: border-box;
  background: $white;
  box-shadow: $shadow-50;
  height: 100%;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @include for-size(phone-only) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.blueContainer {
    background: $primary-800;
    color: $white;
  }
}

.arrow {
  cursor: pointer;
  padding: 5px;
  margin: 0 10px;
  width: 30px;
  height: 30px;
}

.whiteArrow {
  color: $white;

  &:hover {
    color: $primary-100;
  }
}

.blackArrow {
  color: $neutral-400;

  &:hover {
    color: $primary-700;
  }
}

.right {
  transform: rotate(-90deg);
}

.left {
  transform: rotate(90deg);
}

.logoContainer {
  position: relative;
  height: 40px;
  margin-bottom: 16px;
}

.logo {
  height: 40px;
  @include for-size(phone-only) {
    height: 40px;
  }
}

.quote {
  font-size: $size-4;
  line-height: $line-height-4;
  white-space: normal;
  padding-bottom: 16px;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-3;
  }
}

.name {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-3;
  }
}

.role {
  font-size: $size-4;
  line-height: $line-height-4;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-3;
  }
}

.company {
  font-size: $size-4;
  line-height: $line-height-4;
  font-weight: 600;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-4;

    display: none;
  }
}

.seeMore {
  margin-top: 40px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
