@import '@hiredigital/ui/variables';

.list {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  // margin: 0 -20px;

  @include for-size(phone-only) {
    display: block;
    margin: 0;
  }

  &.cardList {
    border: 1px solid $gray-10;
    border-radius: $card-border-radius;
    background-color: $white;
    margin-left: -1px;
    margin-top: -1px;
    overflow: hidden;
  }
}

.item {
  width: 300px;
  flex-basis: 33%;
  font-weight: $fw-1;
  box-sizing: border-box;

  @include for-size(phone-only) {
    width: 100%;
    padding: 15px 0 15px 0;
  }
}

.itemChild {
  padding: 30px 20px;

  @include for-size(tablet-landscape-down) {
    padding: 30px 5px;
  }
  @include for-size(phone-only) {
    padding: 0;
  }
}

.headline {
  color: $black;
  font-size: $size-5;
  line-height: $line-height-5;
  font-weight: $fw-2;
  margin-bottom: 8px;

  @include for-size(phone-only) {
    font-size: $size-4;
    line-height: $line-height-4;
  }
}

.content {
  color: $black;
  font-size: $size-3;
  line-height: $line-height-3;
}

.icon {
  // background-color: $blue-5;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  // border-radius: 50%;
  margin-bottom: 20px;
  line-height: 0;

  div {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }
}

.smallIcon {
  // background-color: $blue-5;
  width: 100px;
  height: 100px;
  line-height: 0;

  box-sizing: border-box;
  border-radius: 50%;
  margin-bottom: 20px;
  padding: 20px;
}

.smallerIcon {
  background-color: $blue-5;
  width: 50px;
  height: 50px;
  line-height: 0;

  box-sizing: border-box;
  border-radius: 50%;
  margin-bottom: 20px;
}

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  // margin: 20px;
  width: calc(33.33% - 80px);
  // background: $white;
  // border-radius: 5px;
  text-decoration: none;
  // transition: all 0.6s $cubic;
  padding: 30px;
  margin: -1px;
  flex-grow: 1;

  @include for-size(tablet-landscape-down) {
    width: calc(50% - 80px);
    // max-width: 480px;
    min-height: 118px;
  }

  @include for-size(phone-only) {
    display: block;
    width: 100%;
    box-sizing: border-box;
    // margin: 10px 0px;
    min-height: 170px;

    // &:last-of-type {
    //   margin-bottom: 0;
    // }
  }
  border-left: 1px solid $gray-10;
  border-top: 1px solid $gray-10;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   top: 0;
  //   left: 0;
  //   border-radius: 5px;
  //   pointer-events: none;
  //   box-shadow: $shadow-20;
  //   transition: 0.2s;
  //   z-index: $z-background;
  // }

  &:not(.noHoverShadow):hover:before {
    box-shadow: $shadow-30;
  }

  &:hover > .action {
    transform: translateY(-2px);

    & > a {
      box-shadow: $shadow-20;
    }
  }
}

.action {
  padding-top: 10px;
  margin-top: auto;
  transition: transform 0.2s $cubic;
}
