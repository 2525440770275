@import '@hiredigital/ui/variables';

.section {
  padding: 50px 20px;

  .title {
    margin-bottom: 32px;
  }

  .description {
    margin-bottom: 20px;
    max-width: 700px;
    color: $neutral-100;
  }

  .subtitle {
    font-weight: $fw-2;
    font-size: $size-6;
    line-height: $line-height-6;
    margin-bottom: 16px;

    @include for-size(phone-only) {
      font-size: $size-4;
      line-height: $line-height-4;
    }
  }

  .itemWrapper {
    margin: -1px;
    // border: 1px solid $gray-20;
    border-radius: $card-border-radius;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: hsla(225, 100%, 18%, 0.3);
      backdrop-filter: blur(30px);
    }
  }

  .itemContainer {
    // border-right: 1px solid $gray-20;
    // border-bottom: 1px solid $gray-20;
    // border-radius: $card-border-radius;
    // overflow: hidden;

    .itemTitle {
      font-size: $size-6;
      line-height: $line-height-6;
      font-weight: $fw-1;
      color: $primary-200;
      max-width: 400px;
      @include for-size(phone-only) {
        font-size: $size-4;
        line-height: $line-height-4;
      }
    }

    .itemDescription {
      margin-bottom: 0;
      max-width: 400px;
      color: $white;

      @include for-size(phone-only) {
        font-size: $size-3;
        line-height: $line-height-3;
      }
    }

    .item {
      border: 1px solid $primary-900;
      // border: 0;
      // width: auto;
      // flex-basis: 50%;

      .listItem {
        // max-width: 400px;
        margin-left: 20px;
        font-size: $size-4;
        line-height: $line-height-4;
        color: $primary-200;
        margin-bottom: 16px;
        &:last-of-type {
          margin-bottom: 0;
        }

        .textItem {
          font-size: $size-3;
          line-height: $line-height-3;
          margin-bottom: 0;
          color: $white;

          @include for-size(phone-only) {
            font-size: $size-2;
            line-height: $line-height-2;
          }
        }
      }

      @include for-size(phone-only) {
        margin: 0;
      }
    }
  }
}
