@use 'sass:math';
@import '@hiredigital/ui/variables';

.override.margin {
  margin-bottom: 50px;
}

.list {
  display: flex;
  // flex-wrap: wrap;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background: $primary-900;
    height: calc(100% + 60px);
    width: calc(100%);
    left: -30px;
    top: -30px;
    z-index: $z-background;
    border-radius: $card-border-radius;
  }

  @include for-size(tablet-landscape-down) {
    justify-content: center;

    &:before {
      height: calc(100%);
      width: calc(100%);
      left: 0;
      top: 0;
    }
  }

  @include for-size(tablet-landscape-down, -100) {
    flex-wrap: wrap;
    padding: 20px;
  }

  @include for-size(phone-only) {
    background: $primary-900;
    z-index: $z-background;
    border-radius: $card-border-radius;

    &:before {
      display: none;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50%);
  font-weight: $fw-1;
  box-sizing: border-box;
  z-index: $z-base;
  margin-right: 30px;

  @include for-size(tablet-landscape-down) {
    margin: 20px;
    flex-basis: 100%;
  }

  @include for-size(tablet-landscape-down, -100) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  @include for-size(phone-only) {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.percentage {
  font-size: $size-11;
  color: $white;
  font-weight: $fw-2;

  @include for-size(phone-only) {
    font-size: 56px;
    font-size: $size-10;
  }
}

.description {
  color: $primary-200;
  font-weight: $fw-2;
  font-size: 20px;
  line-height: 1.75;
  margin: 0 0 20px 0;

  @include for-size(phone-only) {
    font-size: 18px;
    line-height: 1.75;
  }
}

.card {
  background: $white;
  padding: 60px 30px;
  border-radius: $card-border-radius;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: $z-base;

  @include for-size(tablet-landscape-down) {
    padding: 30px;
    flex-basis: 70%;
  }
  @include for-size(tablet-landscape-down, -100) {
    flex-basis: 100%;
  }

  @include for-size(phone-only) {
    margin: 0 0 20px;

    padding: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.description2 {
  font-size: $size-5;
  line-height: $line-height-5;
  margin-bottom: 20px;
  @include for-size(phone-only) {
    font-size: $size-4;
    line-height: $line-height-4;
  }
}

.sourceText {
  color: $neutral-300;
  text-decoration: none;

  @include for-size(tablet-landscape-down) {
    // font-size: 14px;
    // line-height: 28px;
  }

  @include for-size(phone-only) {
    // line-height: 20px;
  }
}

.actionBtn {
  margin-top: 20px;
  max-width: 250px;

  @include for-size(tablet-landscape-down) {
    max-width: 100%;
    margin: 20px auto;
  }
}

.actionSpacer {
  margin-bottom: 84px;
  @include for-size(phone-only) {
    margin-bottom: 0;
  }
}
