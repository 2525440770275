@import '@hiredigital/ui/variables';

.title {
  text-align: center;
}

.imageArea {
  margin: 0 auto;
  width: 100%;
  flex-grow: 1;
}

.image {
  display: block;
  @include for-size(tablet-portrait-down) {
    display: none;
  }
}

.mobileImage {
  display: none;
  @include for-size(tablet-portrait-down) {
    display: block;
  }
}

.padding {
  padding: 150px 0 50px;
}

.override.section {
  padding: 50px;
  background-color: $white;
  border-radius: $border-radius;
}
