@import '@hiredigital/ui/variables';

.hero {
  padding: 240px 20px 20px;

  @include for-size(tablet-landscape-down) {
    height: auto;
    padding: 100px 20px 20px;
  }
}
.heroCta {
  margin-bottom: 48px;
  @include for-size(tablet-landscape-down) {
    margin-bottom: 24px;
  }
}

.heroBottom {
  margin: 0;
}
